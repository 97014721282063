import { useNavigate, useLocation } from "react-router-dom"
import useResponsive from "../../utils/media-query"
import cn from "classnames"
const Headline = ({ menus, title }) => {
    const location = useLocation()
    const { pathname } = location
    const slug = pathname.substring(1)
    const { isMobile } = useResponsive()
    const navigate = useNavigate()

    function getTitle() {
        if (menus.length > 0) {
            
            const filteredMenus = menus.filter(item => item.data.slug.includes(slug))
            if (filteredMenus.length > 0) {
                const titleParent = filteredMenus[0].data.name
                return titleParent
            } else {
                const filteredData = menus.map(item => {
                    if (item.child && item.child.length > 0) {
                        const filteredChild = item.child.filter(childItem => childItem.slug === slug);
                        return {
                            ...item, 
                            child: filteredChild 
                        };
                    }
                    return item; 
                });

                const filteredChild = filteredData.filter(item => item.child.length > 0)
                if (filteredChild.length > 0 && filteredChild[0].child.length > 0) {
                    const titleChild = filteredChild[0].child[0].name
                    return titleChild
                } else {
                    return title
                }
            }
        } else {
            return title
        }
    }
    return (
        <>
            {
                getTitle() && getTitle() != "" &&
                <div className={cn("flex bg-primary w-full justify-between items-center",
                    isMobile ? "flex-col p-[1.5rem]" : "px-[11rem] py-[1.7rem] flex-row")}>
                    <span className={cn(isMobile ? "text-[1.438rem] font-semibold text-white mb-[0.2rem]" : "text-[1.563rem] font-semibold text-white")}>
                        {getTitle()}
                    </span>
                    <div className={cn("flex flex-row items-center", isMobile ? "gap-x-[0.8rem]" : "gap-x-[1rem]")}>
                        <span className="text-white hover:cursor-pointer text-[0.813rem] font-normal" onClick={() => {
                            navigate("/")
                        }}>Beranda</span>
                        <span className="circle w-[0.469rem] h-[0.469rem] bg-secondary"></span>
                        <span className="text-white hover:cursor-pointer text-[0.813rem] font-normal">{getTitle()}</span>
                    </div>
                </div>
            }
        </>
    )
}

export default Headline