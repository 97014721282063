import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Beranda from "./Beranda"
import SusunanPengurus from "./tentang-kami/SusunanPengurus"
import Portofolio from "./tentang-kami/Portofolio"
import DetailPage from "./DetailPage"
import LaporanPublikasi from "./tentang-kami/LaporanPublikasi"
import BeritaKebaikan from "./berita/BeritaKebaikan"
import Program from "./program"
import DetailBerita from "./berita/DetailBerita"
import Sejarah from "./tentang-kami/Sejarah"
import Page from "./template/Page"
const Onepager = ({ footer, menus, programs, statistik, posts, banner, mitra }) => {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Routes>
                <Route
                    exact path="/"
                    element={
                        <Beranda
                            footer={footer}
                            menus={menus}
                            programs={programs}
                            statistik={statistik}
                            posts={posts}
                            banner={banner}
                            mitra={mitra}
                        />
                    }
                />

                <Route
                    path="/category/:slug"
                    element={
                        <BeritaKebaikan
                            footer={footer}
                            menus={menus}
                            title={"Program"}
                        />
                    }
                />

                <Route
                    path="/parent-program/:slug"
                    element={
                        <Program
                            footer={footer}
                            menus={menus}
                        />
                    }
                />

                <Route
                    path="/daftar-laporan-publikasi/:slug"
                    element={
                        <DetailPage
                            footer={footer}
                            menus={menus}
                            title={"Laporan & Publikasi"}
                            prefix={"/daftar-laporan-publikasi/"}
                        />
                    }
                />
                <Route
                    path="/portofolio/:slug"
                    element={
                        <DetailPage
                            footer={footer}
                            menus={menus}
                            title={"Portofolio"}
                            prefix={"/portofolio/"}
                        />
                    }
                />



                <Route
                    path="/list-program/:slug"
                    element={
                        <DetailPage
                            footer={footer}
                            menus={menus}
                            title={"Program"}
                        />
                    }
                />

                <Route
                    path="/:path/:slug"
                    element={
                        <DetailBerita
                            footer={footer}
                            menus={menus}
                        />
                    }
                />

                <Route
                    path="/:slug"
                    element={
                        <Page
                            footer={footer}
                            menus={menus}
                        />
                    }
                />

                {/* <Route
                    path="/susunan-pengurus"
                    element={
                        <SusunanPengurus
                            footer={footer}
                            menus={menus}
                        />
                    }
                />
                <Route
                    path="/portofolio"
                    element={
                        <Portofolio
                            footer={footer}
                            menus={menus}
                        />
                    }
                />
                <Route
                    path="/daftar-laporan-publikasi"
                    element={
                        <LaporanPublikasi
                            footer={footer}
                            menus={menus}
                        />
                    }
                /> */}

                {/* <Route
                    path="/sejarah"
                    element={
                        <Sejarah
                            footer={footer}
                            menus={menus}
                        />
                    }
                /> */}
            </Routes>
        </Router>
    )
}

export default Onepager;