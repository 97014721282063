import React, { useEffect, useState } from "react";
import Prelaunch from "./pages"
import { getHome } from "./api";
import Loading from "./components/layout/Loading";

function App() {
  const [menus, setMenus] = useState([])
  const [footer, setFooter] = useState({
    disclaimer: "",
    socialMedia: {},
    data: []
  })
  const [programs, setPrograms] = useState([])
  const [statistik, setStatistik] = useState()
  const [posts, setPosts] = useState([])
  const [banner, setBanner] = useState([])
  const [mitra, setMitra] = useState({
    title: "",
    data: []
  })
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    getHome()
      .then((response) => {
        const data = response.data
        setMenus(data.menu)
        setFooter({
          disclaimer: data.disclaimer,
          socialMedia: data.social_media,
          data: data.footer ? data.footer : []
        })
        setPrograms(data.programs)
        setStatistik(data.statistik)
        setPosts(data.posts)
        setBanner(data.banners)
        setMitra(data.mitra)
      }).catch((e) => {

      }).finally(() => {
        setLoading(false)
      })
  }, [])
  return (
    <div>
      {!loading &&
        <Prelaunch
          footer={footer}
          menus={menus}
          programs={programs}
          statistik={statistik}
          banner={banner}
          posts={posts}
          mitra={mitra} />
      }
      {loading && <Loading/>}
    </div>
  )
}

export default App;