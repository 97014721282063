import React from "react"
import { useState } from "react"

const LpCircleButton = ({ direction, onClick }) => {
    const [isHover, setIsHover] = useState(false)

    const chevronRight = `${process.env.PUBLIC_URL}/icons/ic_chevron_right.png`
    const chevronRightWhite = `${process.env.PUBLIC_URL}/icons/ic_chevron_right_white.png`
    const chevronLeft = `${process.env.PUBLIC_URL}/icons/ic_chevron_left.png`
    const chevronLeftWhite = `${process.env.PUBLIC_URL}/icons/ic_chevron_left_white.png`
    return (
        <div
            className="circle w-[2.188rem] h-[2.188rem] hover:bg-primary bg-[#929292] flex justify-center items-center hover:cursor-pointer"
            onMouseOver={() => {
                setIsHover(true)
            }}
            onMouseOut={() => {
                setIsHover(false)
            }}
            onClick={() => {
                onClick()
            }}>
            <img
                src={direction == "right" ?
                    (isHover ? chevronRightWhite : chevronRight) :
                    (isHover ? chevronLeftWhite : chevronLeft)
                }
                className="w-[0.938rem] h-[0.938rem]"
            />
        </div>
    )
}

export default LpCircleButton