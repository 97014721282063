import Layout from "../../components/layout/Layout"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { getContent } from "../../api"
import DOMPurify from "dompurify"
import { useNavigate } from "react-router-dom"
import cn from "classnames"
import useResponsive from "../../utils/media-query"
export default function Program({ footer, menus }) {
    const { isMobile } = useResponsive()
    const { slug } = useParams()
    const [data, setData] = useState()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        getContent(slug)
            .then((response) => {
                const data = response.data.data
                setData(data)
            }).catch((e) => {

            }).finally(() => {
                setLoading(false)
            })
    }, [slug])

    const Item = ({ data }) => {
        return (
            <div className="w-full flex flex-col">
                <img src={data.image} className="object-cover rounded-lg min-h-[17.813rem] max-h-[17.813rem]" />
                <span className="mt-[1rem] lp-text-subhead-2 line-clamp-1">
                    {data.name}
                </span>
                <div
                    className="text-[0.938rem] mt-[0.5rem] leading-[1.563rem] text-[#211E1E] font-normal line-clamp-4 content-html"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }}>
                </div>
                <span className="text-primary lp-text-body-1 font-bold hover:cursor-pointer mt-[1rem]" onClick={() => {
                    navigate(`/${data.slug}`)
                }}>
                    Selengkapnya
                </span>
            </div>
        )
    }

    return (
        <>
            {data &&
                <Layout title={data.name} footer={footer} menus={menus} loading={loading}>
                    <div className={cn("flex flex-col", isMobile ? "px-[1rem] py-[2rem]" : "py-[6rem] px-[11rem]")}>
                        <div className={cn("flex", isMobile ? "flex-col-reverse gap-y-[1rem] " : "flex-row gap-x-[2rem] mb-[5rem]")}>
                            <div className="leading-[1.6rem] text-[1rem] font-normal text-justify mt-[0.5rem] content-html" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }}>

                            </div>
                            <img src={data.image} className={cn("object-cover rounded-lg", isMobile ? "w-full min-h-[14.875rem] max-h-[14.875rem]" : "min-w-[35.625rem] min-h-[20rem] max-w-[35.625rem] max-h-[20rem]")} />
                        </div>

                        {/* <span className="lp-text-body-2 font-bold text-primary">
                            Tonton Program LAZnas PHR lebih lengkap klik link dibawah,
                        </span>
                        <button className="btn btn-primary btn-sm w-[9.375rem] h-[2.5rem] lp-text-button capitalize mt-[1rem]">Selengkapnya</button> */}
                        <span className={cn("font-bold text-black text-center", isMobile ? "text-[1.438rem] mt-[3rem]" : "text-[1.563rem]")}>{`Program ${data.name}`}</span>
                        <div className={cn("grid", isMobile ? "grid-cols-1 mt-[2rem] gap-y-[2rem]" : "grid-cols-3 gap-x-[1.5rem] gap-y-[2rem] mt-[1.5rem]")}>
                            {data.listing.data.length > 0 && data.listing.data.map((listing, key) => {
                                return (
                                    <Item key={key} data={listing} />
                                )
                            })}
                        </div>
                        {/* <div className="flex justify-center mt-[4rem]">
                            <button className="btn btn-secondary btn-sm w-[9.063rem] h-[2.5rem] lp-text-button capitalize">Load More</button>
                        </div> */}
                    </div>
                </Layout>
            }
        </>
    )
}