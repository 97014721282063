import Layout from "../../components/layout/Layout"
import { useEffect, useState } from "react"
import { getContent } from "../../api"
import DOMPurify from "dompurify"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import LpButton from "../../components/atom/LpButton"
import cn from "classnames"
import useResponsive from "../../utils/media-query"
import { useParams } from "react-router-dom"

export default function BeritaKebaikan({ footer, menus }) {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { isMobile } = useResponsive()
    const { slug } = useParams()

    useEffect(() => {
        setLoading(true)
        getContent(`category/${slug}`)
            .then((response) => {
                const data = response.data.data
                setData(data)
            }).catch((e) => {

            }).finally(() => {
                setLoading(false)
            })
    }, [slug])

    const Info = ({ icon, text }) => {
        return (
            <div className="flex flex-row items-center gap-x-[0.5rem] my-[0.5rem]">
                <img src={icon} className="w-[0.875rem] h-[0.875rem]" />
                <span className="lp-text-caption text-[#96989b] font-normal">
                    {text}
                </span>
            </div>
        )
    }

    const Item = ({ data, title }) => {
        return (
            <div className="w-full flex flex-col">
                <div className="min-h-[18.75rem] max-h-[18.75rem] relative">
                    <img src={data.image} className="rounded-lg w-full h-full object-cover" />
                    <span className="absolute bottom-0 left-0 text-primary bg-white px-[1rem] py-[0.5rem] lp-text-caption font-bold rounded-bl-md">{title}</span>
                </div>
                <span className="mt-[1rem] lp-text-subhead-1 line-clamp-2 font-semibold min-h-[3.5rem]">
                    {data.name}
                </span>
                <Info icon={`${process.env.PUBLIC_URL}/icons/ic_clock_grey.png`} text={moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format("D MMMM YYYY")} />
                <div
                    className="text-[0.938rem] mt-[0.5rem] leading-[1.563rem] text-[#211E1E] font-normal line-clamp-3 content-html"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }}>
                </div>
                <div>
                    <LpButton title={"Lanjut Baca"} slug={`/${slug}/${data.slug}`} />
                </div>
            </div>
        )
    }

    return (
        <>
            {data &&
                <Layout title={data.name} footer={footer} menus={menus} loading={loading}>
                    <div className={cn("flex flex-col", isMobile ? "px-[1rem] py-[3rem]" : "py-[3rem] px-[11rem]")}>
                        <div className={cn("grid grid-cols-1", isMobile ? "gap-y-[3rem]" : "grid-cols-3 gap-x-[1.5rem] gap-y-[3rem] mt-[1.5rem]")}>
                            {data.listing.data.length > 0 && data.listing.data.map((listing, key) => {
                                return (
                                    <Item key={key} data={listing} title={data.name} />
                                )
                            })}
                        </div>
                    </div>
                </Layout>
            }
        </>
    )
}