import Layout from "../components/layout/Layout"
import { useState, useEffect } from "react"
import { getContent } from "../api"
import { useParams } from "react-router-dom"
import DOMPurify from "dompurify"
import moment from "moment"
import LpButton from "../components/atom/LpButton"
import useResponsive from "../utils/media-query"
import cn from "classnames"

export default function DetailPage({ footer, menus, title, prefix }) {
    const { isMobile } = useResponsive()
    const { slug } = useParams()
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getContent(slug)
            .then((response) => {
                const data = response.data.data
                setData(data)
            }).catch((e) => {

            }).finally(() => {
                setLoading(false)
            })
    }, [])
    const Item = ({ data }) => {
        return (
            <div className="flex flex-row gap-x-[1rem] cursor-pointer" onClick={() => {
                if (prefix) {
                    window.open(`${process.env.PUBLIC_URL}${prefix}${data.slug}`, "_self")
                } else {
                    window.open(`${process.env.PUBLIC_URL}/${data.slug}`, "_self")
                }
            }}>
                <img src={data.image} className="min-w-[11.25rem] min-h-[6.875rem] max-w-[11.25rem] max-h-[6.875rem] rounded-md" />
                <div className="flex flex-col">
                    <span className="text-[0.938rem] font-medium text-[#44474b]">{data.name}</span>
                    <span className="lp-text-caption text-[#adadad]">{moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format("D MMMM YYYY")}</span>
                </div>
            </div>
        )
    }

    const Info = ({ icon, text }) => {
        return (
            <div className="flex flex-row items-center gap-x-[0.3rem]">
                <img src={icon} className="w-[0.875rem] h-[0.875rem]" />
                <span className="lp-text-body-1 text-[#44474b] font-medium">
                    {text}
                </span>
            </div>
        )
    }
    return (
        <>
            {data &&
                <Layout title={title == "Program" ? data.name : title} footer={footer} menus={menus} loading={loading}>
                    <div className={cn("flex flex-col", isMobile ? "px-[1rem] py-[2rem]" : "py-[3rem] px-[11rem] mb-[3rem]")}>
                        <div className={cn("flex", isMobile ? "flex-col" : "flex-row")}>
                            <div className={cn("flex flex-col", isMobile ? "" : "mr-[2.5rem]")}>
                                <span className={cn("text-black mt-[1rem]", isMobile ? "lp-text-headline-1" : "lp-text-display-2")}>
                                    {data.name}
                                </span>
                                <div className="flex flex-row my-[1rem] gap-x-[2rem]">
                                    <Info icon={`${process.env.PUBLIC_URL}/icons/ic_circle_user_primary.png`} text={"Admin Laznas"} />
                                    <Info icon={`${process.env.PUBLIC_URL}/icons/ic_clock_primary.png`} text={`Diterbitkan: ${moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format("D MMMM YYYY")}`} />
                                </div>


                                <img src={data.image} className={cn("rounded-lg object-cover", isMobile ? "min-h-[18.5rem] max-h-[18.5rem]" : "max-h-[34.188rem] min-h-[34.188rem]")} />
                                <div
                                    className="lp-text-body-2 font-normal mt-[2rem] content-html"
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }}>
                                </div>
                                {data.cta_link && <div>
                                    <LpButton
                                        title={data.cta_text.toLowerCase()}
                                        cta={data.cta_link}
                                        icon={`${process.env.PUBLIC_URL}/icons/ic_download_white.png`} />
                                </div>}
                            </div>
                            <div className="flex flex-col max-w-[21.25rem] min-w-[21.25rem] mt-[3rem]">
                                <span className="lp-text-subhead-2 font-semibold text-primary mb-[1.2rem]">{`${title} Lainnya`}</span>
                                <div className="flex flex-col gap-y-[2rem]">
                                    {data.listing.data.length > 0 && data.listing.data.map((listing, index) => {
                                        return (
                                            <Item data={listing} key={index} />
                                        )
                                    })}
                                </div>

                            </div>
                        </div>
                    </div>
                </Layout>
            }
        </>

    )
}