const getColSpanByWidth = (width) => {
    if (width == "1") {
        return "col-span-1";
    } else if (width == "2") {
        return "col-span-2";
    } else if (width == "3") {
        return "col-span-3";
    } else if (width == "4") {
        return "col-span-4";
    } else if (width == "5") {
        return "col-span-5";
    } else if (width == "6") {
        return "col-span-6";
    } else if (width == "7") {
        return "col-span-7";
    } else if (width == "8") {
        return "col-span-8";
    } else if (width == "9") {
        return "col-span-9";
    } else if (width == "10") {
        return "col-span-10";
    } else if (width == "11") {
        return "col-span-11";
    } else if (width == "12") {
        return "col-span-12";
    }
}

export {
    getColSpanByWidth
}