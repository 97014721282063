import Layout from "../../components/layout/Layout"
import { useEffect, useState } from "react"
import { getContent } from "../../api"
import DOMPurify from "dompurify"
import { useParams, useNavigate } from "react-router-dom"
import useResponsive from "../../utils/media-query"
import cn from "classnames"
import LpButton from "../../components/atom/LpButton"

export default function Page({ footer, menus }) {
    const { slug } = useParams()
    const [data, setData] = useState()
    const navigate = useNavigate()
    const { isMobile } = useResponsive()
    const [loading, setLoading] = useState(false)

    const gridColsDesktop = data && data.tipe == "PORTOFOLIO" ? "grid-cols-2" : "grid-cols-3"

    useEffect(() => {
        setLoading(true)
        getContent(slug)
            .then((response) => {
                const data = response.data.data
                setData(data)
            }).catch((e) => {

            }).finally(() => {
                setLoading(false)
            })
    }, [slug])

    const Portofolio = ({ data }) => {
        return (
            <div className="w-full flex flex-col hover:cursor-pointer" onClick={() => {
                navigate(data.slug)
            }}>
                <img src={data.image} className={cn("rounded-xl", isMobile ? "min-h-[12.5rem] max-h-[12.5rem]" : "min-h-[18.75rem] max-h-[18.75rem]")} />
                <span className={cn("mt-[0.5rem] font-semibold ", isMobile ? "text-[0.938rem]" : "lp-text-subhead-1")}>
                    {data.name}
                </span>
                <div
                    className={cn("mt-[0.5rem] line-clamp-3 text-justify", isMobile ? "lp-text-body-1" : "lp-text-body-1")}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }} />
            </div>
        )
    }

    const Laporan = ({ data }) => {
        return (
            <div className="w-full flex flex-col hover:cursor-pointer">
                <img src={data.image} className="rounded-lg min-h-[15.625rem] max-h-[15.625rem]" />
                <span className="mt-[1rem] lp-text-title-1 font-semibold line-clamp-2 min-h-[5rem]">
                    {data.name}
                </span>
                <div className="lp-text-body-2 leading-[1.563rem] text-[#211E1E] font-normal content-html line-clamp-4"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }}
                />
                <div className="grid grid-cols-2 gap-x-[0.5rem]">
                    <LpButton
                        title={"Lihat Detail"}
                        slug={`/daftar-laporan-publikasi/${data.slug}`}
                        align={"justify-between"} />
                    <LpButton
                        title={data.cta_text ? data.cta_text.toLowerCase() : ""}
                        cta={data.cta_link ? data.cta_link : ""}
                        isOutline={true}
                        align={"justify-between"}
                        icon={`${process.env.PUBLIC_URL}/icons/ic_download_primary.png`} />
                </div>
            </div>
        )
    }

    return (
        <>
            {data &&
                <Layout footer={footer} menus={menus} title={data.name} loading={loading}>
                    <div className={cn("flex flex-col", isMobile ? "px-[1rem] py-[2rem]" : "py-[3rem] px-[11rem] mb-[3rem]")}>
                        <span className={cn("text-black", isMobile ? "lp-text-title-1 font-semibold" : "lp-text-display-2")}>
                            {data.name}
                        </span>
                        <div className="justify-center w-full items-center flex">
                            <img src={data.image} className={cn("max-w-full object-cover rounded-xl max-h-[35.625rem]", isMobile ? "my-[2rem]" : "mt-[3rem] mb-[1rem]")} />
                        </div>
                        <div
                            className={cn("content-html", isMobile ? "" : "mt-[2rem]")}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }} />
                        <div className={cn("grid", isMobile ? "grid-cols-1 gap-y-[2rem] mt-[4rem]" : `${gridColsDesktop} mt-[3rem] gap-x-[1.5rem] gap-y-[2rem]`)}>
                            {data.listing && data.listing.data && data.listing.data.length > 0 &&
                                data.listing.data.map((listing, index) => {
                                    return (
                                        <div key={index}>
                                            {data.listing.tipe == "PORTOFOLIO" &&
                                                <Portofolio key={index} data={listing} />
                                            }
                                            {data.listing.tipe != "PORTOFOLIO" &&
                                                <Laporan key={index} data={listing} />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Layout>
            }
        </>
    )
}