import 'react-multi-carousel/lib/styles.css';
import 'aos/dist/aos.css';
import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/layout/Footer";
import LpButton from "../components/atom/LpButton";
import cn from "classnames"
import LpCircleButton from "../components/atom/LpCircleButton";
import Carousel from 'react-multi-carousel';
import { getColSpanByWidth } from '../utils/helper';
import { useNavigate } from 'react-router-dom';
import useResponsive from "../utils/media-query";
import AOS from 'aos';
import CountUp from 'react-countup';
export default function Beranda({ footer, menus, programs, statistik, posts, banner, mitra }) {
    const { isMobile } = useResponsive()
    const navigate = useNavigate()

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, [])
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    function splitMenus() {
        if (menus.length == 0) {
            return []
        }
        const splitIndex = Math.floor(menus.length / 2);
        const firstHalf = menus.slice(0, splitIndex);
        const secondHalf = menus.slice(splitIndex);

        return [firstHalf, secondHalf];
    }

    function Navbar() {
        const [expandNavbar, setExpandNavbar] = useState()
        const [expandMenuMobile, setExpandMenuMobile] = useState(false)
        const [expandChildMenuMobile, setExpandChildMenuMobile] = useState()
        function onHoverNavbar(index, isHover) {
            if (isHover) {
                setExpandNavbar(index)
            } else {
                setExpandNavbar(null)
            }
        }

        function ItemNavbar({ data, onHover, flag }) {
            function navigateTo(menu) {
                if (menu.name == "Beranda") {
                    navigate("/")
                } else {
                    navigate(menu.slug)
                }
            }
            const menu = data.data
            const child = data.child
            return (
                <div className="flex flex-col p-[1.25rem] relative">
                    <div
                        className="flex flex-row items-center"
                        onMouseOver={() => {
                            onHover(true)
                        }}
                        onClick={() => {
                            if (child.length == 0 || menu.slug == "tentang-kami") {
                                navigateTo(menu)
                            }
                        }}
                    >
                        <div className="lp-text-subhead-1 text-white hover:cursor-pointer">{menu.name}</div>
                        {child.length > 0 && <img src={`${process.env.PUBLIC_URL}/icons/ic_chevron_down_white.png`} className="ml-[0.2rem] w-[1rem] h-[1rem]" />}
                    </div>
                    {child.length > 0 &&
                        <div
                            className="mt-[2rem] rounded-md bg-white -ml-[0.9rem] absolute flex flex-col w-[13rem]"
                            onMouseOver={() => {
                                onHover(true)
                            }}
                            onMouseOut={() => {
                                onHover(false)
                            }}
                        >
                            {flag == expandNavbar && <ChildNavbar child={child} />}
                        </div>
                    }
                </div>
            )
        }

        function ChildNavbar({ child }) {
            const Item = ({ name, slug }) => {
                return (
                    <div
                        className="px-[1rem] py-[0.5rem] lp-text-body-2 font-medium cursor-pointer text-black hover:bg-primary hover:text-white"
                        onClick={() => {
                            navigate(slug)
                        }}
                    >
                        {name}
                    </div>
                )
            }
            return (
                <>
                    {child.map((data, index) => {
                        return (
                            <Item key={index} name={data.name} slug={data.slug} />
                        )
                    })}
                </>
            )
        }


        return (
            <>
                <div id="navbar" className={cn("absolute top-0 w-full flex flex-row py-[1rem] z-[99]", isMobile ? "" : "items-center justify-between px-[9.7rem]")}>
                    {!isMobile && <div className="flex flex-row">
                        {splitMenus().length > 0 && splitMenus()[0].map((data, index) => {
                            return (
                                <ItemNavbar
                                    data={data}
                                    key={index}
                                    flag={`${0}${index}`}
                                    onHover={(isHover) => {
                                        onHoverNavbar(`${0}${index}`, isHover)
                                    }} />
                            )
                        })}
                    </div>}
                    {isMobile &&
                        <span className="mt-[2rem] ml-[1.5rem] lp-text-subhead-2 text-white hover:cursor-pointer" onClick={() => {
                            setExpandMenuMobile(true)
                        }}>MENU</span>
                    }
                    <img src={`${process.env.PUBLIC_URL}/icons/ic_logo.png`} className={cn(isMobile ? "w-[4.875rem] h-[4.5rem] absolute inset-0 mx-auto mt-[1.5rem]" : "w-[5.313rem] h-[5.313rem]")} />
                    {!isMobile && <div className="flex flex-row">
                        {splitMenus().length > 0 && splitMenus()[1].map((data, index) => {
                            return (
                                <ItemNavbar
                                    data={data}
                                    key={index}
                                    flag={`${1}${index}`}
                                    onHover={(isHover) => {
                                        onHoverNavbar(`${1}${index}`, isHover)
                                    }} />
                            )
                        })}
                    </div>}

                    {isMobile && expandMenuMobile && <div className="w-full top-0 fixed h-full bg-black bg-opacity-50 z-[999]">
                        <div className="w-[80%] h-full bg-white p-[1.5rem] flex flex-col overflow-y-auto">
                            <img src={`${process.env.PUBLIC_URL}/icons/ic_close.png`} className='w-[1.25rem] h-[1.25rem] self-end mb-[1rem] hover:cursor-pointer' onClick={() => {
                                setExpandMenuMobile(false)
                            }} />
                            <img src={`${process.env.PUBLIC_URL}/icons/ic_logo_main.png`} className="w-[6.75rem] h-[6.75rem]" />
                            <div className='flex flex-col mt-[1.5rem] gap-y-[0.5rem]'>
                                {menus.length > 0 && menus.map((menu, index) => {
                                    const detail = menu.data
                                    const child = menu.child
                                    const chevronDown = `${process.env.PUBLIC_URL}/icons/ic_chevron_down.png`
                                    const chevronDownWhite = `${process.env.PUBLIC_URL}/icons/ic_chevron_down_white.png`
                                    return (
                                        <div className='flex flex-col' key={index}>
                                            <div
                                                className={cn(
                                                    'px-[1rem] py-[0.5rem] flex flex-row hover:cursor-pointer items-center gap-x-[0.3rem]',
                                                    (index == 0 || expandChildMenuMobile == index) ? "bg-primary text-white" : ""
                                                )}
                                                onClick={() => {
                                                    if (detail.slug == "beranda") {
                                                        setExpandMenuMobile(false)
                                                    }
                                                    else if (child.length > 0) {
                                                        setExpandChildMenuMobile(index)
                                                    } else {
                                                        navigate(detail.slug)
                                                    }
                                                }}
                                            >
                                                <span className={cn('text-[0.938rem] font-semibold', (index == 0 || expandChildMenuMobile == index) ? "text-white" : "text-black")}>{detail.name}</span>
                                                {child.length > 0 && <img src={(index == 0 || expandChildMenuMobile == index) ? chevronDownWhite : chevronDown} className='w-[0.938rem] h-[0.938rem]' />}
                                            </div>
                                            {/* child section */}
                                            {expandChildMenuMobile == index && <div className='flex flex-col'>
                                                {child.length > 0 && child.map((detailChild, indexChild) => {
                                                    return (
                                                        <div
                                                            key={indexChild}
                                                            className='hover:cursor-pointer px-[1.5rem] py-[0.5rem] text-[0.938rem] font-semibold hover:cursor-pointer' onClick={() => {
                                                                navigate(detailChild.slug)
                                                            }}>{detailChild.name}</div>
                                                    )
                                                })}
                                            </div>}
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>}
                </div>

            </>
        )
    }

    function Hero() {
        return (
            <div className="relative">
                {banner.length > 0 &&
                    <Carousel
                        responsive={responsive}
                        swipeable={true}
                        infinite={true}
                        arrows={false}
                        customTransition="all .5"
                        transitionDuration={500}
                        showDots={true}
                        dotListClass={"indicator-hero"}
                    >
                        {banner.map((data, index) => {
                            return (
                                <video
                                    key={index}
                                    className={cn(isMobile ? "object-cover " : "object-fill", "w-full h-[42.5rem] max-h-[42.5rem]")}
                                    muted
                                    autoPlay
                                    loop
                                >
                                    <source src={data.file} type="video/mp4" />
                                </video>
                            )
                        })}
                    </Carousel>
                }


            </div >
        )
    }

    function Headline() {
        function ButtonGroupPost({ next, previous, goToSlide, ...rest }) {
            return (
                <div className={cn("flex flex-row gap-x-[0.5rem] ", isMobile ? "mx-[1.5rem] mb-[3rem] justify-end mt-[1.5rem]" : "absolute bottom-[11rem] right-[11rem]")}>
                    <LpCircleButton direction="left" onClick={() => previous()} />
                    <LpCircleButton direction="right" onClick={() => next()} />
                </div>
            )
        }

        function Post({ data }) {
            return (
                <div className={cn("flex w-full", isMobile ? "flex-col px-[1.5rem] pt-[3rem]" : "flex-row")}>
                    <img data-aos="fade" className={cn("rounded rounded-xl object-cover", isMobile ? "w-full h-[16.875rem]" : "w-[30.875rem] h-[29.375rem]")} src={data.image}></img>
                    <div data-aos="fade-up" className={cn("flex flex-col", isMobile ? "" : "px-[2.5rem] py-[3rem]")}>
                        <div className={cn("text-[1.563rem] font-semibold", isMobile ? "my-[1.5rem]" : "mb-[1.5rem]")}>{data.name}</div>
                        <div className="text-[#575A5F] font-normal lp-text-body-2 leading-[1.563rem] line-clamp-[8]">{data.excerpt} </div>
                        <div>
                            <LpButton title={"Lanjut Baca"} slug={`/berita-kebaikan/${data.slug}`} />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className={cn(isMobile ? "" : "p-[11rem]", "relative")}>
                <Carousel
                    responsive={responsive}
                    swipeable={false}
                    infinite={true}
                    arrows={false}
                    tran
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ButtonGroupPost />}
                >
                    {posts.length > 0 && posts.map((post, index) => {
                        return (
                            <Post key={index} data={post} />
                        )
                    })}
                </Carousel>
            </div>
        )
    }

    function ListProgram() {
        function Program({ data }) {
            const desktop = `${getColSpanByWidth(data.width)} flex relative h-[36.25rem] max-h-[36.25rem] justify-center`
            const mobile = "col-span-12 flex flex relative h-[25.25rem] max-h-[25.25rem] justify-center"
            return (
                <div className={cn(isMobile ? mobile : desktop)}>
                    <img src={data.image} className='object-cover' />
                    <div className='overlay'></div>
                    <div className="flex flex-col absolute bottom-0 mb-[3rem] items-center z-[1]">
                        <div className={cn("lp-text-body-1 text-white font-semibold uppercase", isMobile ? "" : "mb-[1rem]")}>Program</div>
                        <div className={cn("text-white lp-text-display-1", isMobile ? "" : "mb-[0.5rem]")}>{data.name}</div>
                        <div>
                            <LpButton title={"Lihat Program"} slug={data.slug} />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="grid grid-cols-12">
                {programs.length > 0 && programs.map((program, index) => {
                    return (
                        <Program key={index} data={program} />
                    )
                })}
            </div>
        )
    }

    function Statisktik() {
        const mitraResponsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 10,
                slidesToSlide: 10
            },
            tablet: {
                breakpoint: { max: 1024, min: 688 },
                items: 6,
                slidesToSlide: 6
            },
            mobile: {
                breakpoint: { max: 688, min: 0 },
                items: 4,
                slidesToSlide: 4
            }
        };


        function Service({ data }) {
            const desktop = `${getColSpanByWidth(data.width)} flex flex-col justify-center items-center`
            const mobile = "col-span-6 flex flex-col justify-center items-center"
            const number = Number(data.number)

            return (
                <div className={cn(isMobile ? mobile : desktop)}
                    data-aos="fade-in"
                >
                    <div className={cn("flex relative circle items-center justify-center bg-primary hover:bg-secondary",
                        isMobile ? "w-[5rem] max-[5rem] h-[5rem] max-h-[5rem]" : "w-[6.75rem] max-w-[6.75rem] max-h-[6.75rem]"
                    )}>
                        <img src={data.image} className="w-[3rem] h-[3rem]" />
                    </div>
                    <div className="flex flex-row items-center mt-[1rem]">
                        <div className={cn("font-bold", isMobile ? "lp-text-title-2" : "lp-text-display-1")}>{<CountUp start={0} end={number} duration={10} separator='.' />}</div>
                        <div className="lp-text-body-2 font-bold mt-[0.5rem] ml-[0.25rem]">{data.unit}</div>
                    </div>
                    <div className={cn("text-[#44474b] text-center", isMobile ? "text-[0.813rem]" : "lp-text-body-2")}>{data.name}</div>
                </div>
            )
        }
        return (
            <>
                {statistik && <div className={
                    cn("flex flex-col", isMobile ? "px-[1.5rem] py-[3rem]" : "p-[11rem]")
                }>
                    <h2 className={cn("text-black text-center", isMobile ? "text-[1.563rem] font-semibold mb-[3rem]" : "lp-text-display-2  mb-[4.438rem]")}>{statistik.title}</h2>
                    <div className={cn("grid grid-cols-12 gap-y-[3rem]", isMobile ? "gap-x-[2rem]" : "")}>
                        {programs.length > 0 && programs.map((data, index) => {
                            if (data.statistik) {
                                return (
                                    <Service data={data.statistik} key={index} />
                                )
                            }
                        })}
                    </div>
                    {/* actions */}
                    <div className={cn("flex justify-center", isMobile ? "flex-col mt-[3rem] gap-y-[1rem]" : "flex-row gap-x-[1rem] mt-[6.25rem]")}>
                        <button className={cn("btn btn-primary btn-outline h-[2.5rem] lp-text-button border-2", isMobile ? "w-full" : "w-[14.313rem]")} onClick={() => {
                            window.open("https://energikebaikan.com/")
                        }}>Infaq Sekarang</button>
                        <button className={cn("btn btn-primary btn-outline h-[2.5rem] lp-text-button border-2", isMobile ? "w-full" : "w-[14.313rem]")} onClick={() => {
                            window.open("https://energikebaikan.com/zakat")
                        }}>Zakat Sekarang</button>
                        <button className={cn("btn btn-primary btn-outline h-[2.5rem] lp-text-button border-2", isMobile ? "w-full" : "w-[14.313rem]")} onClick={() => {
                            window.open("https://energikebaikan.com/zakat/kalkulator")
                        }}>Kalkulator Sekarang</button>
                    </div>
                    {/* mitra kebaikan */}
                    {mitra &&
                        <>
                            <div className={cn("font-bold text-center", isMobile ? "text-[1.563rem] mt-[3rem]" : "mt-[6.25rem] lp-text-display-2")}>{mitra.title}</div>
                            <Carousel
                                responsive={mitraResponsive}
                                infinite={true}
                                arrows={false}
                                customTransition="all .5"
                                transitionDuration={500}
                                autoPlay={true}
                                showDots={true}

                            >
                                {mitra.data.length > 0 && mitra.data.map((data, index) => {
                                    return (
                                        <img src={data.file} key={index}
                                            className="object-scale-down rounded rounded-xl max-w-[5.625rem] max-h-[5.625rem] w-[5.625rem] h-[5.625rem] my-[1rem]" />
                                    )
                                })}
                            </Carousel>
                        </>
                    }
                </div>}
            </>
        )
    }
    return (
        <div>
            <Navbar />
            <Hero />
            <Headline />
            <ListProgram />
            <Statisktik />
            <Footer data={footer} />
        </div>
    );
}
