import DOMPurify from "dompurify"
import cn from "classnames"
import { getColSpanByWidth } from "../../utils/helper"
import useResponsive from "../../utils/media-query"
const Footer = ({ data }) => {
    const { isMobile } = useResponsive()
    const footer = data.data
    const information = footer[0]
    const address = footer[1]
    const contact = footer[2]

    const Socmed = ({ url, slug }) => {
        return (
            <img src={url} className="hover:cursor-pointer rounded-xl min-w-[1.875rem] min-h-[1.875rem] max-w-[1.875rem] max-h-[1.875rem]" onClick={() => {
                if (slug) {
                    window.open(slug, '_blank')
                }
            }} />
        )
    }
    return (
        <>
            {/* footer */}
            <div className={cn("w-full flex flex-col bg-[#e8e8e8]", isMobile ? "px-[1rem] py-[3rem]" : "px-[11rem] py-[3.25rem]")}>
                {/* information */}
                <div className={cn("grid grid-cols-12", isMobile ? "gap-y-[3rem]" : "gap-x-[3rem]")}>
                    {information &&
                        <div className={cn(`lp-text-body-1 leading-[1.313rem] text-[#44474b] text-justify`, isMobile ? "col-span-12" : getColSpanByWidth(information.width))}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(information.content) }} />
                    }
                    {address && <div className={cn(`flex flex-col`, isMobile ? "col-span-12" : getColSpanByWidth(address.width))}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(address.content) }}>
                    </div>}
                    {contact && <div className={cn(`flex flex-col`, isMobile ? "col-span-12" : getColSpanByWidth(contact.width))}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contact.content) }}>
                    </div>}
                </div>
                {/* socmed */}
                <div className="flex flex-row gap-x-[1rem] mt-[1rem]">
                    <Socmed url={`${process.env.PUBLIC_URL}/icons/ic_facebook.png`} slug={data.socialMedia.facebook} />
                    <Socmed url={`${process.env.PUBLIC_URL}/icons/ic_ig.png`} slug={data.socialMedia.instagram} />
                    <Socmed url={`${process.env.PUBLIC_URL}/icons/ic_twitter.png`} slug={data.socialMedia.xtwitter} />
                    <Socmed url={`${process.env.PUBLIC_URL}/icons/ic_tiktok.png`} slug={data.socialMedia.tiktok} />
                    <Socmed url={`${process.env.PUBLIC_URL}/icons/ic_linkedin.png`} slug={data.socialMedia.linkedin} />
                    <Socmed url={`${process.env.PUBLIC_URL}/icons/ic_yt.png`} slug={data.socialMedia.youtube} />
                </div>
                {/* warning */}
                <div className={cn("justify-between items-center", isMobile ? "flex flex-col mt-[3rem]" : "flex flex-row mt-[2.25rem]")}>
                    <div className={cn("flex flex-row bg-white rounded-md p-[1rem] gap-x-[1.25rem]", isMobile ? "w-full" : "w-[40.313rem]")}>
                        <img src={`${process.env.PUBLIC_URL}/icons/users-alt.svg`} className="w-[3.125rem] h-[3.125rem]" />
                        <div className="text-justify text-[0.813rem] font-normal text-black">
                            {data.disclaimer}
                        </div>
                    </div>
                    <div className={cn("lp-text-body-1 font-semibold text-[#44474b]", isMobile ? "mt-[3rem]" : "")}>© Copyright 2024 | laznasphr.id</div>
                </div>
            </div>
        </>
    )
}

export default Footer;