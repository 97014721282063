import Navbar from "./Navbar"
import Headline from "./Headline"
import Footer from "./Footer"
import Loading from "./Loading"
const Layout = ({ children, title, menus, footer, noHeadline, loading }) => {
    return (
        <>
            {!loading &&
                <div className="flex flex-col">
                    <Navbar menus={menus} />
                    {!noHeadline && <Headline menus={menus} title={title} />}
                    {children}
                    <Footer data={footer} />
                </div>
            }
            {loading &&
                <Loading />
            }
        </>
    )
}

export default Layout