import API from "../utils/api";

const getHome = () => {
    return API.get("home")
}

const getContent = (slug) => {
    return API.get("content", {
        params: {
            "menu_slug": slug
        }
    })
}

export { getHome, getContent }